import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import { Box, Container, Grid, Typography, Button } from '@material-ui/core/'

import config from '../config'
import { useIpData } from '../utils/hooks'

const LearnMoreSection = ({ classes }) => {
	const ipData = useIpData()
	const [contactUrl, setContactUrl] = useState(config.REQUEST_DEMO_URL_INT)
	useEffect(() => {
		if (ipData?.country_code === 'SA') {
			setContactUrl(config.REQUEST_DEMO_URL_SA)
		}
	}, [ipData])
	return (
		<section className={cx(classes.section, classes.mutedBackground)}>
			<Container>
				<Box mb={4}>
					<Typography
						color={'inherit'}
						align={'center'}
						gutterBottom
						variant={'h4'}
					>
						Want to learn more?
					</Typography>
				</Box>
				<Grid container spacing={6} justify={'center'}>
					<Grid item sm={6} className={classes.paddedCell}>
						<Typography gutterBottom variant={'h5'}>
							Attend a webinar
						</Typography>
						<Typography paragraph>
							See how Tyto improves team culture through
							transparency, autonomy, clarity and trust.
							(Duration: 10 minutes)
						</Typography>
						<Button
							variant={'contained'}
							color={'primary'}
							component={'a'}
							href={contactUrl}
							rel={'noopener noreferrer'}
							target={'_blank'}
						>
							Save Your Seat!
						</Button>
					</Grid>
					<Grid item sm={6} className={classes.paddedCell}>
						<Typography gutterBottom variant={'h5'}>
							Request a demo
						</Typography>
						<Typography paragraph>
							We'll review your biggest pain-points and brainstorm
							together on how to make your team super productive.
						</Typography>
						<Button
							variant={'contained'}
							color={'primary'}
							component={'a'}
							href={contactUrl}
							rel={'noopener noreferrer'}
							target={'_blank'}
						>
							Book Your Demo Now
						</Button>
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default LearnMoreSection
