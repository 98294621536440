import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import cx from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Container, Grid, Typography } from '@material-ui/core'
import {
	Video as VideoIcon,
	Message as ChatIcon,
	ClipboardCheck as TasksIcon,
	FolderMultiple as ProjectsIcon,
	LightningBoltCircle as RealtimeIcon,
	EmoticonCoolOutline as FunIcon,
} from 'mdi-material-ui'

import { container } from '../sharedStyles'
import {
	FeatureBox,
	FeatureList,
	FeatureTabs,
	FeatureGrid,
} from '../siteblocks'
import avatar1 from '../images/avatar-1.png'
import avatar2 from '../images/avatar-2.png'
import myTasksScreenshot from '../images/homepage-my-tasks-screenshot.png'
import HeroScreenshot from '../siteblocks/Hero/HeroScreenshot'
import LearnMoreSection from '../components/LearnMoreSection'
import Layout from '../components/Layout'
//https://images.unsplash.com/photo-1517059224940-d4af9eec41b7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1467&q=80

const AnchorButton = ({ children, ...other }) => {
	return (
		<Button
			color={'primary'}
			component={GatsbyLink}
			disableElevation
			variant={'contained'}
			{...other}
		>
			{children}
		</Button>
	)
}

const useStyles = makeStyles(
	theme => ({
		root: { flexGrow: 1 },
		container: container(theme),
		section: theme.mixins.gutters({
			paddingBottom: theme.spacing(10),
			paddingTop: theme.spacing(10),
		}),
		secondaryBackground: {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		mutedBackground: {
			backgroundColor: theme.palette.background.emphasis,
		},
		content: {
			padding: theme.spacing(2),

			[theme.breakpoints.up('xl')]: {
				padding: theme.spacing(12),
			},
			[theme.breakpoints.only('lg')]: {
				padding: theme.spacing(9),
			},
			[theme.breakpoints.only('md')]: {
				padding: theme.spacing(6),
			},
			[theme.breakpoints.only('sm')]: {
				padding: theme.spacing(4),
			},
		},
		leftContent: {},
		rightContent: {},
		paddedCell: { padding: theme.spacing(2) },
		image: {
			borderRadius: 2,
			maxWidth: '100%',
			alignSelf: 'center',
			[theme.breakpoints.down('sm')]: {
				maxWidth: '75%',
			},
		},
		imageMarginBottom: {
			marginBottom: theme.spacing(2),
			maxWidth: '100%',
		},
		avatar: {
			borderRadius: 100,
		},
		heading: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '2rem',
			},
		},
	}),
	{ name: 'IndexPage' }
)

const IndexPage = () => {
	const classes = useStyles()

	return (
		<Layout>
			<div className={classes.root}>
				<HeroScreenshot
					title={'A new way to connect to your remote team.'}
					subtitle={
						'Tyto is an all-in-one remote work tool for teams, with tasks, projects, video and chat.'
					}
					imageUrl={myTasksScreenshot}
				/>

				<FeatureGrid
					heading={'Everything you need, all in one space.'}
					features={[
						{
							title: 'Video',
							icon: VideoIcon,
							content:
								'Call teammates directly or host group chat, without leaving the app.',
						},
						{
							title: 'Chat',
							icon: ChatIcon,
							content:
								'Message teammates either one-to-one, or against projects or even directly at task level.',
						},
						{
							title: 'Tasks',
							icon: TasksIcon,
							content:
								'Get yourself and your team more productive.',
						},
						{
							title: 'Projects',
							icon: ProjectsIcon,
							content:
								'Plan, delegate, prioritize and review multiple projects— all with fewer meetings.',
						},
						{
							title: 'Real-time',
							icon: RealtimeIcon,
							content:
								'See task progress in real-time, to improve focus and reduce interruptions.',
						},
						{
							title: 'Fun!',
							icon: FunIcon,
							content:
								'With weekly challenges, multiple themes and scoring, getting to work never was this fun!',
						},
					]}
				/>

				<FeatureList
					heading={'Bring your team closer'}
					features={[
						{ title: 'Built in (group) chat' },
						{ title: 'Map deadlines & timeline' },
						{ title: 'Real-time updates' },
						{ title: '​File & document sharing' },
						{ title: 'Video conferencing & screen sharing' },
						{ title: 'Any 3rd party app integration & much more' },
					]}
				/>

				<FeatureBox
					heading={'Collaborate more than ever before'}
					body={
						"Working from home doesn't have to mean an end to collaboration. We believe collaboration thrives through transparency. That's why we baked it in from the start, allowing teams to be anywhere, yet feel more connected than ever."
					}
					action={
						<AnchorButton to={'/signup'}>
							See It In Action
						</AnchorButton>
					}
					imageUrl={
						'https://images.unsplash.com/photo-1498409785966-ab341407de6e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1660&q=80'
					}
					reverse
					color={'#f5f5f5'}
				/>

				<FeatureBox
					heading={'Set clear priorities'}
					body={
						'Set your team up for success by setting organized, weekly, clear objectives— then leaving your team to get on with it, instead of pacing around the office, annoying everyone— like some kind of wild animal.'
					}
					action={
						<AnchorButton to={'/signup'}>
							See It In Action
						</AnchorButton>
					}
					imageUrl={
						'https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80'
					}
				/>

				<FeatureBox
					heading={'Let your team fly'}
					body={
						'With Tyto, your team will be working more efficiently than ever before. Get through tasks, capture ideas and deploy them in an organized, fun and efficient manner.'
					}
					action={
						<AnchorButton to={'/signup'}>
							See It In Action
						</AnchorButton>
					}
					imageUrl={
						'https://images.unsplash.com/photo-1420310414923-bf3651a89816?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80'
					}
					reverse
					color={'#f5f5f5'}
				/>

				<FeatureTabs
					heading={'4 powerful modes to get your work done'}
					features={[
						{
							title: 'Grind',
							content: (
								<FeatureBox
									overline={'GRIND'}
									heading={'Organize yourself like a pro'}
									body={
										"We've created an advanced to-do list, that's gamified too. Find yourself immersed in a new level of productivity through our intuitive tasking engine, scoring and more."
									}
									action={
										<AnchorButton to={'/signup'}>
											See It In Action
										</AnchorButton>
									}
									imageUrl={
										'https://images.unsplash.com/photo-1499914485622-a88fac536970?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80'
									}
								/>
							),
						},
						{
							title: 'Brainstorm',
							content: (
								<FeatureBox
									overline={'BRAINSTORM'}
									heading={
										'Capture everything, then organize'
									}
									body={
										'Capture your best ideas without distraction. Organize, and delegate them later.'
									}
									action={
										<AnchorButton to={'/signup'}>
											See It In Action
										</AnchorButton>
									}
									imageUrl={
										'https://images.unsplash.com/photo-1568219557405-376e23e4f7cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80'
									}
									reverse
									color={'#f5f5f5'}
								/>
							),
						},
						{
							title: 'Manage',
							content: (
								<FeatureBox
									overline={'MANAGE'}
									heading={'Tyto is best in teams'}
									body={
										'Bring your team closer with live scheduling and distraction-free prioritizing.'
									}
									action={
										<AnchorButton to={'/signup'}>
											See It In Action
										</AnchorButton>
									}
									imageUrl={
										'https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80'
									}
								/>
							),
						},
						{
							title: 'Deploy',
							content: (
								<FeatureBox
									overline={'DEPLOY'}
									heading={'Get any project out'}
									body={
										'Organize and visualise projects per person, per category or per status.'
									}
									action={
										<AnchorButton to={'/signup'}>
											See It In Action
										</AnchorButton>
									}
									imageUrl={
										'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'
									}
									reverse
									color={'#f5f5f5'}
								/>
							),
						},
					]}
				/>

				<section
					className={cx(classes.section, classes.secondaryBackground)}
				>
					<Typography
						align={'center'}
						color={'inherit'}
						gutterBottom
						variant={'h4'}
					>
						Kind Words From Early Adopters
					</Typography>

					<Grid container className={classes.container} spacing={5}>
						<Grid item sm={6}>
							<Grid
								container
								alignItems={'center'}
								direction={'column'}
								spacing={2}
							>
								<Grid item>
									<Typography
										align={'center'}
										color={'inherit'}
										variant={'h6'}
									>
										"I've been through a ton of productivity
										apps for my team, never getting the
										results I was looking for. After just 2
										days with Tyto, I find myself eager to
										start my workday."
									</Typography>
								</Grid>
								<Grid item>
									<img
										className={classes.avatar}
										alt={'quote avatar'}
										src={avatar1}
									/>
								</Grid>
								<Grid item>
									<Typography
										color={'inherit'}
										variant={'h6'}
									>
										<em>— Pavel Popovichenko</em>
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item sm={6}>
							<Grid
								container
								alignItems={'center'}
								direction={'column'}
								spacing={2}
							>
								<Grid item>
									<Typography
										align={'center'}
										color={'inherit'}
										variant={'h6'}
									>
										"The score feature is awesome. Everyone
										here at our office has really had fun
										with it so far and each day feels like a
										race to see who can grind through the
										most tasks."
									</Typography>
								</Grid>
								<Grid item>
									<img
										className={classes.avatar}
										alt={'quote avatar'}
										src={avatar2}
									/>
								</Grid>
								<Grid item>
									<Typography
										color={'inherit'}
										variant={'h6'}
									>
										<em>— Gregg Richardson</em>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</section>

				{/*<section className={cx(classes.section, classes.mutedBackground)}>
			<Grid container className={classes.container}>
				<Grid item sm={6}>
					<div className={cx(classes.content, classes.leftContent)}>
						<Typography color="inherit" gutterBottom variant="h4">
							Bring your team closer
						</Typography>
						<Typography color="inherit" paragraph>
							We believe collaboration thrives through
							transparency. That's why we baked it in from the
							start, allowing teams to be anywhere, yet feel more
							connected than ever.
						</Typography>
						Collaboration is a rare creature, found only in areas
						rich with trust…
						<AnchorButton to="/signup">
							See It In Action
						</AnchorButton>
					</div>
				</Grid>

				<Grid item container justify="center" sm={6}>
					<img
						className={classes.image}
						alt="Tyto at Work"
						src={tytoUser}
					/>
				</Grid>
			</Grid>
		</section>

		<section className={cx(classes.section)}>
			<Grid container className={classes.container}>
				<Grid item container justify="center" sm={6}>
					<img
						className={classes.image}
						alt="Tyto at Work"
						src={tytoUser}
					/>
				</Grid>
				<Grid item sm={6}>
					<div className={cx(classes.content, classes.leftContent)}>
						<Typography color="inherit" gutterBottom variant="h4">
							Keep your team on-task and busy
						</Typography>
						<Typography color="inherit" paragraph>
							Set your team up for success by setting organized,
							weekly, clear objectives— then leaving your team to
							get on with it, instead of pacing around the office,
							annoying everyone— like some kind of wild animal.
						</Typography>
						Collaboration is a rare creature, found only in areas
						rich with trust…
						<AnchorButton to="/signup">
							See It In Action
						</AnchorButton>
					</div>
				</Grid>
			</Grid>
		</section>

		<section className={cx(classes.section, classes.secondaryBackground)}>
			<Grid container className={classes.container}>
				<Grid item sm={6}>
					<div className={cx(classes.content, classes.leftContent)}>
						<Typography color="inherit" gutterBottom variant="h4">
							4 powerful work
							<br />
							modes to help get your
							<br />
							work done!
						</Typography>

						<Typography color="inherit">
							With Tyto, your team will be working more
							efficiently than ever before. Get through tasks,
							capture ideas and deploy them in an organized, fun
							and efficient manner.
						</Typography>
					</div>
				</Grid>

				<Grid item container justify="center" sm={6}>
					<img
						className={classes.image}
						alt="Tyto at Work"
						src={tytoUser}
					/>
				</Grid>
			</Grid>
		</section>

		<section className={classes.section}>
			<Grid container className={classes.container}>
				<Grid item container justify="center" sm={6}>
					<img
						className={classes.image}
						alt="Tyto works brilliantly as a personal to-do list"
						src={imgOrganize}
					/>
				</Grid>
				<Grid item sm={6}>
					<div className={cx(classes.content, classes.rightContent)}>
						<Typography color="secondary" variant="h6">
							GRIND
						</Typography>
						<Typography gutterBottom variant="h4">
							Organize yourself like a pro.
						</Typography>

						<Typography color="textSecondary" variant="subtitle1">
							We've created an advanced to-do list, that's
							gamified too. Find yourself immersed in a new level
							of productivity through our intuitive tasking
							engine, scoring and more.
						</Typography>
					</div>
				</Grid>
			</Grid>
		</section>

		<section className={cx(classes.section, classes.mutedBackground)}>
			<Grid container className={classes.container}>
				<Grid item sm={6}>
					<div className={cx(classes.content, classes.leftContent)}>
						<Typography color="secondary" variant="h6">
							BRAINSTORM
						</Typography>
						<Typography gutterBottom variant="h4">
							Capture everything, then organize.
						</Typography>

						<Typography color="textSecondary" variant="subtitle1">
							Capture your best ideas without distraction.
							Organize, and delegate them later.
						</Typography>
					</div>
				</Grid>

				<Grid item container justify="center" sm={6}>
					<img
						className={classes.image}
						alt="Tyto - Brainstorm"
						src={imgBrainstorm}
					/>
				</Grid>
			</Grid>
		</section>

		<section className={classes.section}>
			<Grid container className={classes.container}>
				<Grid item container justify="center" sm={6}>
					<img
						className={classes.image}
						alt="Tyto - Manage"
						src={manage}
					/>
				</Grid>

				<Grid item sm={6}>
					<div className={cx(classes.content, classes.rightContent)}>
						<Typography color="secondary" variant="h6">
							MANAGE
						</Typography>
						<Typography gutterBottom variant="h4">
							Tyto is best in teams.
						</Typography>

						<Typography color="textSecondary" variant="subtitle1">
							Bring your team closer with live scheduling and
							distraction-free prioritizing.
							<br />
						</Typography>
					</div>
				</Grid>
			</Grid>
		</section>

		<section className={cx(classes.section, classes.mutedBackground)}>
			<Grid container className={classes.container}>
				<Grid item sm={6}>
					<div className={cx(classes.content, classes.leftContent)}>
						<Typography color="secondary" variant="h6">
							DEPLOY
						</Typography>
						<Typography gutterBottom variant="h4">
							Get any project out.
						</Typography>

						<Typography color="textSecondary" variant="subtitle1">
							Organize and visualise projects per person, per
							category or per status.
						</Typography>
					</div>
				</Grid>

				<Grid item container justify="center" sm={6}>
					<img
						className={classes.image}
						alt="Tyto - Deploy"
						src={deploy}
					/>
				</Grid>
			</Grid>
		</section>

		<section className={classes.section}>
			<Grid container className={classes.container} spacing={3}>
				<Grid item sm={4}>
					<img
						className={cx(classes.image, classes.imageMarginBottom)}
						alt="Feature"
						src={teamFeature}
					/>
					<Typography gutterBottom variant="h5">
						Your Team Will Love Tyto
					</Typography>

					<Typography>
						With fewer meetings, fewer interruptions and shorter
						emails, what's not to love about Tyto? Working as a team
						has never been easier - or more effective. Plus, it’s
						totally gamified, so it's fun!
					</Typography>
				</Grid>

				<Grid item sm={4}>
					<img
						className={cx(classes.image, classes.imageMarginBottom)}
						alt="Feature"
						src={customersFeature}
					/>
					<Typography gutterBottom variant="h5">
						Your Customers Will Love Tyto
					</Typography>

					<Typography>
						Say goodbye to tedious conference calls, spreadsheets
						and mails. Tyto is easy enough for everyone to use and
						provides customers with up-to-the-minute insights into
						projects and tasks.
					</Typography>
				</Grid>

				<Grid item sm={4}>
					<img
						className={cx(classes.image, classes.imageMarginBottom)}
						alt="Feature"
						src={managerFeature}
					/>
					<Typography gutterBottom variant="h5">
						Your Managers Will Love Tyto
					</Typography>

					<Typography>
						Tyto provides a clear view on workloads, schedules and
						available resources, with the ability to prioritize and
						reorganize on the fly. What's more, team members earn
						points for productivity – and productivity has never
						been this addictive!
					</Typography>
				</Grid>
			</Grid>
		</section>*/}
				<LearnMoreSection classes={classes} />

				<section
					className={cx(classes.section, classes.secondaryBackground)}
				>
					<Container>
						<Box mb={4}>
							<Typography
								align={'center'}
								color={'inherit'}
								paragraph
								variant={'h4'}
							>
								Get Your Team On The Same Page
							</Typography>
						</Box>
						<Box align={'center'}>
							<Button
								color={'primary'}
								href={'/signup'}
								size={'large'}
								variant={'contained'}
							>
								Sign Up Today
							</Button>
						</Box>
					</Container>
				</section>
			</div>
		</Layout>
	)
}

export default IndexPage
