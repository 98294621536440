import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import { Hero, HeroSubtitle, HeroTitle } from './index'

const useStyles = makeStyles(theme => ({
	screenshotWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(8),
		position: 'relative',
		alignItems: 'center',
	},
	screenshot: {
		boxShadow: theme.shadows[20],
		borderRadius: 2,
		display: 'block',
		transition: theme.transitions.create(),
		width: '90%',
		'&:hover': {
			boxShadow: theme.shadows[24],
			transform: 'scale( 1.01 )',
		},
		[theme.breakpoints.up('lg')]: {
			borderRadius: 8,
			width: '70%',
		},
		[theme.breakpoints.only('md')]: {
			borderRadius: 6,
			width: '70%',
		},
		[theme.breakpoints.only('sm')]: {
			borderRadius: 4,
			width: '80%',
		},
	},
}))

const HeroScreenshot = ({
	imageAlt = 'screenshot',
	imageUrl,
	subtitle,
	title,
	...other
}) => {
	const classes = useStyles()
	return (
		<Hero {...other}>
			<HeroTitle title={title} />
			<HeroSubtitle title={subtitle} />

			<div className={classes.screenshotWrapper}>
				<img
					src={imageUrl}
					className={classes.screenshot}
					alt={imageAlt}
				/>
			</div>
		</Hero>
	)
}

HeroScreenshot.propTypes = {
	imageAlt: PropTypes.string,
	imageUrl: PropTypes.string,
	subtitle: PropTypes.string,
	title: PropTypes.string,
}

export default HeroScreenshot
